
import { Component, Vue } from 'vue-property-decorator'
import { BuildFacilitiesInfo } from '@/types/buildFacilities.d'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'
import { MaintenanceForm } from '@/types/common'

@Component({
  name: 'BuildFacilities',
  components: { MaintenanceDialog }
})
export default class extends Vue {
  private info = {
    projectName: '',
    sceneryName: ''
  }

  private sceneryId = ''
  private loading = false
  private tableData: BuildFacilitiesInfo[] = []
  private showDialog = false
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<BuildFacilitiesInfo> }>(this.$apis.scenery.selectSceneryByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'sceneryAdd'
    })
  }

  onUpdate (sceneryId: string) {
    this.$router.push({
      name: 'sceneryUpdate',
      params: { sceneryId }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.scenery.deleteScenery, {
          sceneryId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onDetail (sceneryId: string) {
    this.$router.push({
      path: `/sceneryList/detail${sceneryId}/sceneryDetail`
    })
  }

  openRecord (sceneryId: string) {
    this.showDialog = true
    this.sceneryId = sceneryId
  }

  // 添加维护记录
  onAddRecord (formData: MaintenanceForm) {
    this.$axios.post(this.$apis.record.insertMaintainRecord, {
      ...formData,
      objectId: this.sceneryId
    }).then(() => {
      this.$message.success('添加成功')
      this.showDialog = false
    }).catch(() => {
      (this.$refs.maintenance as MaintenanceDialog).saveDisabledShow()
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
